import React from 'react';
import Header from '../../components/header/Header';
import Privacypolicy from '../MediaMorph/Privacypolicy';

function privacy() {
    return (
      <>
      <Header/>
      <main>
          <br /><br /><br /><br />
      
    <Privacypolicy/>
      </main>
      </>
    )
  }
  
  export default privacy
  