export const DeployProjectsImages = [

    {
        imageURL: "https://i.postimg.cc/8zFRgxNZ/Whats-App-Image-2023-01-26-at-23-49-42.jpg",
    },
    {
        imageURL: "https://i.postimg.cc/mgnC841b/Whats-App-Image-2023-01-27-at-00-15-24.jpg",
    },
    {
        imageURL: "https://i.postimg.cc/ydV2V2sS/Untitled-design-1.png",
    },
    {
        imageURL: "https://i.postimg.cc/D08ZFVZ3/Whats-App-Image-2023-08-20-at-17-14-35-Copy-1.jpg",
    },
    {
        imageURL: "https://i.postimg.cc/ry4J4Rxx/Untitled-3.jpg",
    },
    {
        imageURL: "",
    },
];
