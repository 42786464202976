import React, { useEffect } from 'react';

const Discord = () => {
  useEffect(() => {
    window.location.href = 'https://discord.gg/MNfwapurrQ';
  }, []);

  return null;
};

export default Discord;
