import React from 'react';
import Header from '../../components/header/Header';
import Mediamorph from '../MediaMorph/MediaMorph';

function privacy() {
    return (
      <>
      <Header/>
      <main>
          <br /><br /><br /><br />
      
    <Mediamorph/>
      </main>
      </>
    )
  }
  
  export default privacy
  