export const osocData = [
    {
        "Name": "Parth Dhavan",
        "Github ID": "ParthDhavan04",
        "Points": 1122
    },
    {
        "Name": "Priyanshu",
        "Github ID": "rampantvoid",
        "Points": 865
    },
    {
        "Name": "Ayush Dangwal",
        "Github ID": "Saga690",
        "Points": 800
    },
    {
        "Name": "Shraddha Sharma",
        "Github ID": "shradiphylleia",
        "Points": 194
    },
    {
        "Name": "Ayush Dey",
        "Github ID": "adx04",
        "Points": 150
    },
    {
        "Name": "Siddhartha Arora",
        "Github ID": "Siddharthaaa21",
        "Points": 100
    },
    {
        "Name": "Mann Rana",
        "Github ID": "the25thjin",
        "Points": 60
    },
    {
        "Name": "Saksham Maggu",
        "Github ID": "sakshammaggu",
        "Points": 20
    },
    {
        "Name": "Kartikey Mishra",
        "Github ID": "XD-coder",
        "Points": 20
    },
    {
        "Name": "Nandini Negi",
        "Github ID": "nandiniiiiiiii",
        "Points": 10
    },
    {
        "Name": "Amulya Jain",
        "Github ID": "AmulyaJain2004",
        "Points": 10
    },
    {
        "Name": "Sanskar Rathore",
        "Github ID": "sanskarrathore15",
        "Points": 10
    },
    {
        "Name": "Pulkit",
        "Github ID": "-",
        "Points": 10
    }
]
